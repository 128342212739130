@keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-15px); /* Move up by 15px */
    }
  }
  
  @keyframes float-reverse {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(15px); /* Move down by 15px */
    }
  }
  
  .animate-float {
    animation: float 4s ease-in-out infinite;
  }
  
  .animate-float-reverse {
    animation: float-reverse 4s ease-in-out infinite;
  }
  