@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

@layer base {
  :root {
    --background: #ffff;
    --text: #1f1e1e;
    --accent: #7cfcb7;
    --button: #202222;
    --button-text: #ffff;
    --light_text: #9CA3AF;
    --gradient-bg: linear-gradient(135deg, #333 0%, #7cfcb7 50%, #34d399 100%);

    /* Font sizes */
    --text-xs: 0.75rem; /* 12px */
    --text-sm: 0.875rem; /* 14px */
    --text-base: 1rem; /* 16px */
    --text-lg: 1.125rem; /* 18px */
    --text-xl: 1.25rem; /* 20px */
    --text-2xl: 1.5rem; /* 24px */
    --text-3xl: 1.875rem; /* 30px */
    --text-4xl: 2.25rem; /* 36px */
    --text-5xl: 3rem; /* 48px */
    --text-6xl: 4rem; /* 64px */

    /* Spacing */
    --spacing-1: 0.25rem; /* 4px */
    --spacing-2: 0.5rem; /* 8px */
    --spacing-3: 0.75rem; /* 12px */
    --spacing-4: 1rem; /* 16px */
    --spacing-5: 1.25rem; /* 20px */
    --spacing-6: 1.5rem; /* 24px */
    --spacing-8: 2rem; /* 32px */
    --spacing-10: 2.5rem; /* 40px */
    --spacing-12: 3rem; /* 48px */
  }

  .dark {
    --background: #1f1e1e;
    --text: #ffff;
    --accent: #7cfcb7;
    --eclipse: #ffff;
    --button: #ffff;
    --button-text: #202222;
    --light_text: #9CA3AF;
    --gradient-bg: linear-gradient(135deg, #1f1e1e 0%, #2c2c2c 100%);
    /* Dark mode font sizes and spacing (if different) */
  }
}


@layer utilities {
  .animate-glow {
    animation: glow 2s infinite ease-in-out;
  }
  .bg-gradient {
    background: var(--gradient-bg);
  }
}


